import { msalInstance } from "..";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const requestAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }

  const request = {
    scopes: ["https://amerb2cdev.onmicrosoft.com/agreements-api/tasks.read"],
    account: activeAccount || accounts[0],
  };
  try {
    const authResult = await msalInstance
      .acquireTokenSilent(request)
      .then(async (response) => response)
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenPopup(request);
        }
        return;
        // handle other errors
      });

    return { token: authResult?.accessToken, account: accounts[0] };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(request);
    }
  }
};
