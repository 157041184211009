import React, { useState } from "react";
import { Step, Stepper } from "@progress/kendo-react-layout";

export const NewStepper = (props) => {
  const [value, setValue] = useState();

  const CustomStep = (props) => {
    return (
      <Step
        {...props}
        onClick={
          props.isEnable
            ? props?.onclick
              ? props.onclick
              : undefined
            : undefined
        }
      >
        <div
          className={`${
            props.isEnable
              ? props.status
                ? "custom-step completed"
                : props.startHere
                ? "custom-step border-solid border-4 border-[#52eb5a]"
                : "custom-step"
              : props.status &&
                (props.label === "AGREEMENT" || props.label === "FINISH")
              ? "custom-step step-disable agreement-step"
              : "custom-step step-disable"
          }`}
        >
          <span>{props.svgIcon}</span>
        </div>
        <span className="step-label">{props.label}</span>
      </Step>
    );
  };

  const handleChange = (e) => {
    setValue(e.value);
  };

  return (
    <React.Fragment>
      <Stepper
        className="custom-stepper"
        onChange={handleChange}
        value={value}
        items={props.customSteps}
        item={CustomStep}
      />
    </React.Fragment>
  );
};
