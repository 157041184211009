import React, { useEffect, useState } from "react";
import {
  handleFormValidation,
  requiredValidator,
  viewErrorMessage,
} from "../CommonFunctions/CommonMethods";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { editContact, fields } from "../MasterComponent/CommonConstants";
import {
  addPointOfContact,
  deletePointOfContact,
  getAgreementDetailsById,
  getAgreementStatus,
  getAgreementType,
  getCountries,
  getEnduserClassification,
  getProvinces,
  getStates,
  getTechnologyTypes,
  setPrimaryUser,
  updateAgreement,
  updatePointOfContact,
} from "../CommonFunctions/ApiCalls";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { SetLoadingOverlay } from "../Redux/Action";
import { NewStepper } from "../MasterComponent/StepperComponent";
import CommonModel from "../MasterComponent/CommonModel";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DrawIcon from "@mui/icons-material/Draw";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { EditableGrid } from "../MasterComponent/CommonGrid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const DATA_ITEM_KEY_AUTHUSER = "id";

const ViewAgreement = (props) => {
  const editFieldContact = "inEdit";

  const [agreement, setAgreement] = useState();
  const [errorMessage, setErrorMessage] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [countries, setCountries] = useState();
  const [technology, setTechnology] = useState([]);
  const [agreementType, setAgreementType] = useState([]);
  const [provinces, setProvinces] = useState();
  const [state, setState] = useState([]);
  const [euClassification, seteuClassification] = useState([]);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [initialAgreement, setInitialAgreement] = useState();
  const [isOpen, setIsOpen] = useState({ open: false, type: null });
  const [agreementTypeFilter, setAgreementTypeFilter] = useState([]);
  const [addDisable, setAddDisable] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [agreementStatus, setAgreementStatus] = useState({});

  let agreementId = useParams("agreementId");

  const navigate = useNavigate();

  useEffect(() => {
    getAgreementById();
    getAllStates();
    getAllProvinces();
    getAllCountries();
    getEUClassifications();
    getAgreementsType();
    getTechnologyType();
    getAgreementStatusApi();
  }, []);

  useEffect(() => {
    if (agreementTypeFilter && agreementTypeFilter.length > 0) {
      let filterType = [...agreementTypeFilter];
      if (!agreement?.technologyId || parseInt(agreement?.technologyId) === 0) {
        filterType = filterType.filter(
          (each) => each.customerType === parseInt(agreement?.customerTypeId)
        );
        setAgreementType(filterType);
      } else {
        filterType = filterType.filter(
          (each) =>
            each.customerType === parseInt(agreement?.customerTypeId) &&
            each.technologyId === agreement?.technologyId
        );
        setAgreementType(filterType);
      }
    }
  }, [
    agreement?.customerTypeId,
    agreement?.technologyId,
    agreement?.agreementTypeId,
  ]);

  useEffect(() => {
    let tempAgreement = { ...agreement };
    tempAgreement.customerTypeId = parseInt(tempAgreement.customerTypeId);
    let isDisabled =
      JSON.stringify(tempAgreement) === JSON.stringify(initialAgreement) ||
      errorMessage?.length !== 0 ||
      tempAgreement.stateId === "0";
    setIsSaveDisable(isDisabled);
    console.log(
      JSON.stringify(tempAgreement) === JSON.stringify(initialAgreement),
      "disable",
      tempAgreement,
      initialAgreement
    );
  }, [agreement]);

  useEffect(() => {
    setContactData(agreement?.contacts);
  }, [agreement?.contacts]);

  const getInputType = (fieldkey, type, dropDown = null, actionType = "") => {
    switch (type) {
      case "type0":
        return (
          <>
            <input
              type="text"
              className={`border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-72 text-size ${
                isDisabled && "cursor-not-allowed text-gray-400"
              }`}
              onChange={(e) => handleOnChange(fieldkey, e, actionType)}
              value={agreement && agreement[fieldkey]}
              disabled={isDisabled}
            />
            <span className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errorMessage)}
            </span>
          </>
        );
      case "type1":
        return (
          <>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              sx={{ fontSize: "small" }}
              className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-72 ${
                isDisabled && "notAllowedCursor"
              }`}
              value={
                agreement && agreement[fieldkey] ? agreement[fieldkey] : "0"
              }
              onChange={(e) => {
                handleOnChange(fieldkey, e, actionType);
              }}
              disabled={isDisabled}
            >
              <MenuItem value="0" sx={{ fontSize: "small" }}>
                Please Select...
              </MenuItem>
              {dropDown.options &&
                dropDown.options.length > 0 &&
                dropDown.options.map((data) => {
                  return (
                    <MenuItem
                      value={data[dropDown.id]}
                      sx={{ fontSize: "small" }}
                    >
                      {data[dropDown.name]}
                    </MenuItem>
                  );
                })}
            </Select>
            <span className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errorMessage)?.replace(
                "Enter",
                "Select"
              )}
            </span>
          </>
        );
      case "type2":
        return (
          <FormControl className="w-full">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="justify-center items-center m-1"
              onChange={(e) => {
                handleOnChange(fieldkey, e, actionType);
              }}
              value={
                agreement && agreement[fieldkey] ? agreement[fieldkey] : ""
              }
            >
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={1}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 1 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="End User"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={2}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 2 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="Dealer"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={3}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 3 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="Distributor"
              />
            </RadioGroup>
            <span className="text-xs text-red-500 ml-20">
              {viewErrorMessage(fieldkey, errorMessage)?.replace(
                "Enter",
                "Select"
              )}
            </span>
          </FormControl>
        );
    }
  };

  const handleOnChange = (fieldKey, e, actionType) => {
    let updateAgreementDetails = { ...agreement };
    updateAgreementDetails[fieldKey] = e.target.value;
    if (fieldKey === "country") {
      updateAgreementDetails.stateId = "0";
    }
    if (fieldKey === "customerTypeId" || fieldKey === "technologyId") {
      if (updateAgreementDetails["agreementTypeId"]) {
        updateAgreementDetails.agreementTypeId = 0;
      }
      console.log(updateAgreementDetails, "updated");
    }
    if (fieldKey === "agreementTypeId") {
      if (
        updateAgreementDetails.agreementTypeId &&
        parseInt(updateAgreementDetails.agreementTypeId) !== 0
      ) {
        updateAgreementDetails.technologyId = agreementType.filter(
          (each) => each.id === updateAgreementDetails.agreementTypeId
        )[0]["technologyId"];
      }
    }
    setAgreement(updateAgreementDetails);
    var field =
      actionType === "create" ? fields.newAgreement : fields.editAgreement;
    if (field.filter((each) => Object.keys(each)[0] === fieldKey).length > 0) {
      let errors = handleFormValidation(
        fieldKey,
        e.target.value,
        errorMessage,
        updateAgreementDetails
      );
      setErrorMessage(errors);
    }
  };

  const handleUpdateAgreement = async () => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      assaAbloyCompany: agreement.company,
      agreementId: agreement.id,
      customerTypeId: parseInt(agreement.customerTypeId),
      companyName: agreement.companyName,
      address: agreement.address,
      address2: agreement.address2,
      address3: agreement.address3,
      address4: agreement.address4,
      city: agreement.city,
      stateId: agreement.stateId,
      postalCode: agreement.postalCode,
      country: agreement.country,
      agreementTypeId: agreement.agreementTypeId,
      updatedByEmailAddress: props.userRole.email,
      technologyId: agreement.technologyId,
      euClassificationID: agreement.euClassificationId,
      customerNumber: agreement.customerNumber,
    };
    await updateAgreement(requestBody)
      .then(async (result) => {
        if (result.statusCode === 200) {
          await getAgreementById();
          toast.success("Agreement Updated Successfully !!!");
        } else {
          toast.error("Something went wrong Please try again later !!!");
        }
      })
      .catch((e) => {
        console.log(e, "err");
        toast.error("Something went wrong Please try again later !!!");
      });
    setIsDisabled(true);
    props.SetLoadingOverlay(false);
  };

  const handleCancelEdit = () => {
    setIsDisabled(true);
    setAgreement(initialAgreement);
    setErrorMessage([]);
  };

  const getAgreementById = async () => {
    props.SetLoadingOverlay(true);
    let tempData = {};
    await getAgreementDetailsById(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setInitialAgreement(data);
          setAgreement(data);
          tempData = { ...data };
        }
      })
      .catch((e) => console.log(e, "error"));
    props.SetLoadingOverlay(false);
    return tempData;
  };

  const getAllStates = async () => {
    await getStates()
      .then((data) => {
        if (data && data.length > 0) {
          setState(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAllProvinces = async () => {
    await getProvinces()
      .then((data) => {
        if (data && data.length > 0) {
          setProvinces(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAllCountries = async () => {
    await getCountries()
      .then((data) => {
        if (data && data.length > 0) {
          setCountries(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getEUClassifications = async () => {
    await getEnduserClassification()
      .then((data) => {
        if (data && data.length > 0) {
          seteuClassification(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAgreementsType = async () => {
    await getAgreementType()
      .then((data) => {
        if (data && data.length > 0) {
          setAgreementType(data);
          setAgreementTypeFilter(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getTechnologyType = async () => {
    await getTechnologyTypes()
      .then((data) => {
        if (data && data.length > 0) {
          setTechnology(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const handleClose = () => {
    setIsOpen({ open: false, type: null });
  };

  const getModelBody = () => {
    if (isOpen.type === "AGREEMENT") {
      return (
        <div className="m-3 text-center">
          <label className="font-semibold">
            Has the agreement has been reviewed and executed by both all
            parties?
          </label>
          <div className="flex justify-around m-5">
            <Button
              variant="contained"
              size="small"
              className="caps-text-size"
              onClick={handleClose}
            >
              YES
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              className="caps-text-size"
              onClick={handleClose}
            >
              NO
            </Button>
          </div>
        </div>
      );
    } else if (isOpen.type === "ACCOUNTING SETUP") {
      return (
        <div className="m-3 text-center">
          <label className="font-semibold">
            Has the accounting setup been complete?
          </label>
          <div className="flex justify-around m-5">
            <Button
              variant="contained"
              size="small"
              className="caps-text-size"
              onClick={handleClose}
            >
              YES
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              className="caps-text-size"
              onClick={handleClose}
            >
              NO
            </Button>
          </div>
        </div>
      );
    } else if (isOpen.type === "RECEIVED PO") {
      return (
        <div className="m-3 text-center">
          <label className="font-semibold">
            Has PO been received & reviewed?
          </label>
          <div className="flex justify-around m-5">
            <Button
              variant="contained"
              size="small"
              className="caps-text-size"
              onClick={handleClose}
            >
              YES
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              className="caps-text-size"
              onClick={handleClose}
            >
              NO
            </Button>
          </div>
        </div>
      );
    }
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    setContactData([newDataItem, ...contactData]);
    setAddDisable(true);
  };

  const itemChangeContact = (event) => {
    const field = event.field || "";
    const newData = contactData.map((item) =>
      item[DATA_ITEM_KEY_AUTHUSER] === event.dataItem[DATA_ITEM_KEY_AUTHUSER]
        ? {
            ...item,
            [field]: event.value,
            [field + "Valid"]: requiredValidator(event.value, event.field),
          }
        : item
    );
    setContactData(newData);
  };

  const handleDeletePointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      id: contactData.id,
      fk_AgreementId: contactData.fk_AgreementId,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: contactData.isPrimary,
    };
    await deletePointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.statusCode === 200 && result.ok) {
          await getAgreementById();
          toast.success("User Deleted Successfully !!!");
        } else {
          toast.error("Please try again later!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleAddPointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      fk_AgreementId: agreement?.id,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: false,
    };
    await addPointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.ok && result.statusCode === 200) {
          await getAgreementById();
          toast.success("New User Added successfully");
        } else {
          toast.error("Unable to add new User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleUpdatePointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      id: contactData.id,
      fk_AgreementId: contactData.fk_AgreementId,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: contactData.isPrimary,
    };
    await updatePointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.ok && result.statusCode === 200) {
          await getAgreementById();
          toast.success("User Updated successfully");
        } else {
          toast.error("Unable to update User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleSetPrimaryUser = async (pocId) => {
    props.SetLoadingOverlay(true);
    await setPrimaryUser(pocId)
      .then(async (result) => {
        console.log(result, "primaryResult");
        if (result && result.message.includes("Updated")) {
          await getAgreementById();
          toast.success("User Updated successfully");
        } else {
          toast.error("Unable to update User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
    console.log(pocId, "pocId");
  };

  const getAgreementStatusApi = async () => {
    await getAgreementStatus(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreementStatus(data);
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  console.log(agreementStatus, "agreementStatus");

  const customSteps = [
    {
      label: "AGREEMENT",
      svgIcon: <HandshakeIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/internal`),
      status: agreementStatus?.isAgreementComplete,
      isEnable: false,
    },
    {
      label: "COINING DIE",
      svgIcon: <DrawIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/internal/coiningdie/${agreementId.agreementId}`),
      status: agreementStatus.isCoiningDieComplete,
      isEnable: agreementStatus && agreementStatus.isCoiningDieComplete,
    },
    {
      label: "MASTER KEY SYSTEM",
      svgIcon: <KeyIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/internal/masterkey-system/${agreementId.agreementId}`),
      status: agreementStatus.isKSDSComplete,
      isEnable: agreementStatus && agreementStatus.isKSDSComplete ,
    },
    {
      label: "KEY SCHEDULE",
      svgIcon: <CalendarMonthIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/internal/key-schedule/${agreementId.agreementId}`),
      status: agreementStatus.isKeyingScheduleComplete,
      isEnable: agreementStatus && agreementStatus.isKeyingScheduleComplete,
    },
  ];

  return (
    <>
      {getInputType("customerTypeId", "type2")}
      <div className="grid grid-cols-6 gap-2 m-5">
        <label className="text-size">
          Company Name<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">{getInputType("companyName", "type0")}</div>
        <label className="text-size">
          {parseInt(agreement?.customerTypeId) === 1 && "Purchasing"} Customer #
        </label>
        <div className="col-span-2">{getInputType("customerId", "type0")}</div>
        <label className="text-size">
          Country<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">
          {getInputType(
            "country",
            "type1",
            {
              options: countries,
              id: "id",
              name: "name",
            },
            "create"
          )}
        </div>
        <label className="text-size">
          Technology<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">
          {getInputType("technologyId", "type1", {
            options: technology,
            id: "id",
            name: "name",
          })}
        </div>
        <label className="text-size">
          Address<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">{getInputType("address", "type0")}</div>
        <label className="text-size">
          Agreement Type<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">
          {getInputType("agreementTypeId", "type1", {
            options: agreementType,
            id: "id",
            name: "type",
          })}
        </div>
        <label className="text-size">Address 2</label>
        <div
          className={`${
            parseInt(agreement?.customerTypeId) === 1
              ? "col-span-2"
              : "col-span-5 w-2/5"
          }`}
        >
          {getInputType("address2", "type0")}
        </div>
        {parseInt(agreement?.customerTypeId) === 1 && (
          <>
            <label className="text-size">Vertical Market</label>
            <div className="col-span-2">
              {getInputType("euClassificationId", "type1", {
                options: euClassification,
                id: "id",
                name: "name",
              })}
            </div>
          </>
        )}
        <label className="text-size">
          City<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-5 w-2/5">{getInputType("city", "type0")}</div>
        <label className="text-size">
          {agreement?.country === "CA" ? "Province" : "State"}
          <span className="text-red-500"> *</span>
        </label>
        <div className="col-span-5 w-2/5">
          {getInputType("stateId", "type1", {
            options: agreement?.country === "CA" ? provinces : state,
            id: "id",
            name: "stateName",
          })}
        </div>
        <label className="text-size">
          Postal Code<span className="text-red-500"> *</span>
        </label>
        <div className="col-span-2">{getInputType("postalCode", "type0")}</div>
        {!agreement?.isComplete &&
          (isDisabled ? (
            <div className="col-span-2 text-right">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setIsDisabled(false);
                }}
                className="caps-text-size"
              >
                {" "}
                Edit
              </Button>
            </div>
          ) : (
            <div className="col-span-2 flex justify-end gap-4">
              <span
                style={
                  isSaveDisable
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleUpdateAgreement();
                  }}
                  className="caps-text-size"
                  startIcon={<SaveIcon />}
                  disabled={isSaveDisable}
                >
                  {" "}
                  Save
                </Button>
              </span>
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleCancelEdit();
                  }}
                  className="caps-text-size"
                  color="error"
                >
                  {" "}
                  cancel
                </Button>
              </span>
            </div>
          ))}
      </div>
      <div className="p-4">
        <Button
          variant="contained"
          size="small"
          className="button-text-decor"
          startIcon={<AddIcon />}
          onClick={() => addNew()}
          disabled={addDisable}
        >
          Add User
        </Button>
        <div className="py-4">
          <EditableGrid
            gridLoading={false}
            DATA_ITEM_KEY={DATA_ITEM_KEY_AUTHUSER}
            columns={editContact}
            height="50px"
            rowHeight={20}
            rowData={contactData}
            setData={setContactData}
            itemChange={itemChangeContact}
            editField={editFieldContact}
            isCrud={true}
            orgGridData={agreement?.contacts ? agreement.contacts : []}
            setAddDisable={setAddDisable}
            addDisable={addDisable}
            handleDelete={handleDeletePointOfContact}
            handleAdd={handleAddPointOfContact}
            handleUpdate={handleUpdatePointOfContact}
            handleSetPrimary={handleSetPrimaryUser}
            addBotton={true}
          />
        </div>
      </div>
      {props.userRole.role === "internal" && (
        <div className="h-36 m-6">
          <div
            style={{
              border: "1px solid #999",
              borderRadius: "8px",
              boxShadow: "0 0 8px #999",
              height: "110px",
              padding: "14px",
            }}
          >
            <NewStepper customSteps={customSteps} />
          </div>
        </div>
      )}

      <CommonModel
        isOpen={isOpen.open}
        onCloseModel={() => setIsOpen({ open: false, type: null })}
        header="complete?"
        body={getModelBody()}
        height="40%"
        size="500px"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAgreement);
