import { Button, Stack, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import VisionImage from "../Assets/Vision.png";
import SurveyImage from "../Assets/Hallway.png";
import LetterImage from "../Assets/Letter.png";
import GuideImage from "../Assets/Guide.png";
import { NewStepper } from "../MasterComponent/StepperComponent";
import CommonModel from "../MasterComponent/CommonModel";
import { AntSwitch } from "../MasterComponent/Overlay";
import { NavLink, useParams } from "react-router-dom";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DrawIcon from "@mui/icons-material/Draw";
import KeyIcon from "@mui/icons-material/Key";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import {
  getAgreementBYId,
  getAgreementStatus,
  getProfessionalServicesOptions,
  getResources,
  submitAgreement,
} from "../CommonFunctions/ApiCalls";
import { handleDownload } from "../CommonFunctions/CommonMethods";
import { SetLoadingOverlay } from "../Redux/Action";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const Agreement = (props) => {
  const [isOpen, setIsOpen] = useState({ open: false, type: null });
  const [resource, setResource] = useState([]);
  const [agreementStatus, setAgreementStatus] = useState({});
  const [agreement, setAgreement] = useState({});
  const [professionalServiceOption, setProfessionalServiceOption] = useState(
    []
  );
  const [selectedProfessionalService, setSelectedProfessionalService] =
    useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getResourcesApi();
    getAgreementStatusApi();
    getAgreement();
    professionalServiceOptionApi();
  }, []);

  let agreementId = useParams("agreementId");

  const getModelBody = () => {
    if (isOpen.type === "FINISH") {
      return (
        <div className="grid grid-cols-2 gap-2 m-5">
          {professionalServiceOption &&
            professionalServiceOption.map((each) => {
              return (
                <>
                  <label className="font-semibold">{each.type}</label>
                  <div>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(e) =>
                          handleProfessionalServiceChanges(
                            each.id,
                            e.target.checked
                          )
                        }
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  </div>{" "}
                </>
              );
            })}
        </div>
      );
    } else if (isOpen.type === "submit") {
      return (
        <div className="flex justify-center items-center flex-col h-full gap-4">
          <label className="font-semibold">
            All forms and questions are complete
          </label>
          <div>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleFinish()}
              className="caps-text-size"
              startIcon={<TaskAltIcon />}
              color="success"
            >
              Submit Pakage To Medeco
            </Button>
          </div>
          <label className="text-sm m-3">
            We will contact you about the next steps after we process your
            information
          </label>
        </div>
      );
    } else if (isOpen.type === "complete") {
      return (
        <div className="flex justify-center items-center flex-col h-full gap-4 m-3">
          <label className="font-semibold">
            Thank you for submitting your agreement packet!
          </label>
          <NavLink
            to={props.userRole.role === "end-user" ? "/end-user" : "/customer"}
          >
            <Button
              variant="outlined"
              size="small"
              color="error"
              className="caps-text-size"
            >
              Close
            </Button>
          </NavLink>
        </div>
      );
    }
  };

  const handleProfessionalServiceChanges = (id, value) => {
    console.log(id, value, "selectedProfessionalServiceidvalue");
    let tempValue = [...selectedProfessionalService];
    if (value) {
      tempValue.push(id);
    } else {
      tempValue = tempValue.filter((each) => each !== id);
    }
    setSelectedProfessionalService(tempValue);
  };

  console.log(selectedProfessionalService, "selectedProfessionalService");

  const getResourcesApi = async () => {
    await getResources(agreementId.agreementId)
      .then((data) => {
        if (data && data.length > 0) {
          setResource(data);
        }
      })
      .catch((e) => console.log(e));
  };

  const getAgreementStatusApi = async () => {
    props.SetLoadingOverlay(true);
    await getAgreementStatus(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreementStatus(data);
        }
      })
      .catch((e) => console.log(e));
    props.SetLoadingOverlay(false);
  };

  const getAgreement = async () => {
    await getAgreementBYId(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreement(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const handleFinish = async () => {
    props.SetLoadingOverlay(true);
    await submitAgreement(agreementId.agreementId, selectedProfessionalService)
      .then((data) => {
        if (data && data.ok) {
          setIsOpen({ open: true, type: "complete" });
        }
      })
      .catch((e) => console.log(e));
    props.SetLoadingOverlay(false);
  };

  const handleComplete = () => {
    setIsOpen({ open: false, type: null });
    setSelectedProfessionalService([]);
    props.userRole.role === "end-user"
      ? navigate("/end-user")
      : navigate("/customer");
  };

  const professionalServiceOptionApi = async () => {
    await getProfessionalServicesOptions(agreementId.agreementId).then(
      (data) => {
        if (data && data.length > 0) {
          setProfessionalServiceOption(data);
        }
      }
    );
  };

  console.log(
    resource,
    "resource",
    resource &&
      resource?.filter((each) => each?.name === "KeySystemDesginStudio")[0]?.url
  );
  const endUserCustomSteps = [
    {
      label: "AGREEMENT",
      svgIcon: <HandshakeIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/end-user/sign-agreement/${agreementId.agreementId}`),
      status: agreementStatus?.isAgreementComplete,
      isEnable: !agreementStatus?.isAgreementComplete,
      startHere: !agreementStatus?.isAgreementComplete,
    },
    {
      label: "COINING DIE",
      svgIcon: <DrawIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/end-user/coiningdie/${agreementId.agreementId}`),
      status: agreementStatus?.isCoiningDieComplete,
      isEnable: true,
    },
    {
      label: "MASTER KEY SYSTEM",
      svgIcon: <KeyIcon htmlColor="white" />,
      onclick: !agreementStatus?.isFinished
        ? () =>
            window.location.replace(
              resource &&
                resource?.filter(
                  (each) => each?.name === "KeySystemDesginStudio"
                ).length > 0 &&
                resource?.filter(
                  (each) => each?.name === "KeySystemDesginStudio"
                )[0]?.url
            )
        : () =>
            navigate(`/end-user/masterkey-system/${agreementId.agreementId}`),
      status: agreementStatus?.isKSDSComplete,
      isEnable: true,
    },
    {
      label: "KEY SCHEDULE",
      svgIcon: <CalendarMonthIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/end-user/key-schedule/${agreementId.agreementId}`),
      status: agreementStatus?.isKeyingScheduleComplete,
      isEnable: true,
    },
    {
      label: "FINISH",
      svgIcon: <VerifiedIcon htmlColor="white" />,
      onclick: () => setIsOpen({ open: true, type: "FINISH" }),
      status: agreementStatus?.isFinished,
      isEnable:
        agreementStatus?.isAgreementComplete &&
        agreementStatus?.isCoiningDieComplete &&
        agreementStatus?.isKSDSComplete &&
        agreementStatus?.isKeyingScheduleComplete &&
        !agreementStatus?.isFinished,
    },
  ];

  const customerCustomSteps = [
    {
      label: "AGREEMENT",
      svgIcon: <HandshakeIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/customer/sign-agreement/${agreementId.agreementId}`),
      status: agreementStatus?.isAgreementComplete,
      isEnable: !agreementStatus?.isAgreementComplete,
      startHere: !agreementStatus?.isAgreementComplete,
    },
    {
      label: "COINING DIE",
      svgIcon: <DrawIcon htmlColor="white" />,
      onclick: () =>
        navigate(`/customer/coiningdie/${agreementId.agreementId}`),
      status: agreementStatus?.isCoiningDieComplete,
      isEnable: true,
    },
    {
      label: "FINISH",
      svgIcon: <VerifiedIcon htmlColor="white" />,
      onclick: () => setIsOpen({ open: true, type: "FINISH" }),
      status: agreementStatus?.isFinished,
      isEnable:
        agreementStatus?.isCoiningDieComplete &&
        agreementStatus?.isAgreementComplete &&
        !agreementStatus?.isFinished,
    },
  ];

  return (
    <>
      <p className="text-3xl font-semibold ml-3">
        WELCOME TO THE {agreement && agreement?.technology} FAMILY!
      </p>
      <p className="mt-3 ml-3">
        Please complete the steps below in black by clicking on the icon
      </p>
      <div
        className="ml-8 mt-5 flex items-center"
        style={{
          border: "1px solid #999",
          borderRadius: "8px",
          boxShadow: "0 0 8px #999",
          height: "110px",
          padding: "14px",
          width: "93%",
        }}
      >
        {!props.isLoading && (
          <>
            {!agreementStatus?.isCoiningDieComplete && (
              <div className="mb-4 font-semibold text-[#1976d2]">
                Start Here
                <span>
                  <KeyboardDoubleArrowRightIcon color="primary" />
                </span>
              </div>
            )}
            <div
              className={`${
                !agreementStatus?.isCoiningDieComplete ? "w-5/6" : "w-full"
              }`}
            >
              <NewStepper
                customSteps={
                  props.userRole.role === "end-user"
                    ? endUserCustomSteps
                    : customerCustomSteps
                }
                status={agreementStatus}
              />
            </div>
          </>
        )}
      </div>
      <div className="ml-11 mt-8">
        <label className="text-2xl font-semibold">Resources</label>
        <div className="w-full flex flex-wrap">
          <div className="m-3 bg-[#F4F4F4] p-2  max-w-60 min-w-44">
            <img src={GuideImage} alt="ResourceImage" />
            <div className="h-14 text-lg font-semibold">
              Record Of Authorization Guide
            </div>
            <div className="text-size h-24">
              A step by step guide to help you complete the Record of
              Authorization process from registration to completion.
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  bgcolor: "#968049",
                  ":hover": {
                    bgcolor: "#968049",
                  },
                }}
                onClick={() => handleDownload(resource, "ROAGuide", "_blank")}
              >
                Download
              </Button>
            </div>
          </div>
          <div className="m-3 bg-[#F4F4F4] p-2 max-w-60 min-w-44">
            <img src={VisionImage} alt="ResourceImage" />
            <div className="h-14 text-lg font-semibold">
              Master Key System Design Guide
            </div>
            <div className="text-size h-24">
              Streamlined instructions for creating efficient and secure access
              hierarchies.
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  bgcolor: "#968049",
                  ":hover": {
                    bgcolor: "#968049",
                  },
                }}
                onClick={() =>
                  handleDownload(resource, "MKDesignGuide", "_blank")
                }
              >
                Download
              </Button>
            </div>
          </div>
          <div className="m-3 bg-[#F4F4F4] p-2 max-w-60 min-w-44">
            <img src={LetterImage} alt="ResourceImage" />
            <div className="h-14 text-lg font-semibold">
              Key System Design Studio Overview
            </div>
            <div className="text-size h-24">
              A brief overview of the Medeco Key System Design Studio and its
              capabilities.
            </div>
            <div className="">
              <Button
                variant="contained"
                size="small"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  bgcolor: "#968049",
                  ":hover": {
                    bgcolor: "#968049",
                  },
                }}
                onClick={() =>
                  handleDownload(resource, "KSDSOverview", "_blank")
                }
              >
                Download
              </Button>
            </div>
          </div>

          <div className="m-3 bg-[#F4F4F4] p-2 max-w-60 min-w-44">
            <img src={SurveyImage} alt="ResourceImage" />
            <div className="h-14 text-lg font-semibold">
              Keying Schedule Template
            </div>
            <div className="text-size h-24">
              Need a place to write down your site survey information? Check out
              our form that has all columns we need to complete your order.
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                startIcon={<CloudDownloadIcon />}
                sx={{
                  bgcolor: "#968049",
                  ":hover": {
                    bgcolor: "#968049",
                  },
                }}
                onClick={() =>
                  handleDownload(resource, "keyingscheduletemplate", "_blank")
                }
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CommonModel
        isOpen={isOpen.open && isOpen.type !== "confirm"}
        onCloseModel={
          isOpen.type === "complete"
            ? () => handleComplete()
            : () => {
                setIsOpen({ open: false, type: null });
                setSelectedProfessionalService([]);
              }
        }
        header={
          isOpen.type === "FINISH"
            ? "Professional Services"
            : isOpen.type === "submit"
            ? "Submit"
            : "Complete"
        }
        body={getModelBody()}
        onSubmit={() => setIsOpen({ open: true, type: "submit" })}
        footer={{
          isVisible: isOpen.type === "FINISH" ? true : false,
          iscancelVisible: false,
          isSubmitVisible: isOpen.type === "FINISH" ? true : false,
        }}
        buttonName={{ submit: "Next", cancel: "" }}
        height="40%"
        size="500px"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agreement);
